import { darken, lighten, rgba, shade } from "polished";

const primaryColor = "#041361";
const primaryGradient = `linear-gradient(147.59deg, #40E2F8 -15.25%, rgba(0, 47, 167, 0.87) 126.89%)`;

export const theme = {
  name: "Default",
  color: {
    background: "#ffffff",
    primary: primaryColor,
    secondary: "#5dadee",
    primaryDark: "#000022",
    primaryGradient,
    gray: "rgba(0, 0, 0, 0.12)",
    sectionBg: rgba("#777777", 0.03),
    light: "#a2a1b71f",
  },
};

import React from "react";
import { FooterSocialLink } from "../FooterSocialLink/FooterSocialLink";
import { useSocialInfo } from "./hooks/useSocialInfo";

export interface FooterContactInfoProps {
  className?: string;
}

export const FooterContactInfo = (props: FooterContactInfoProps) => {
  const { className = "flex flex-col space-y-4" } = props;
  const contactLinks = useSocialInfo();
  return (
    <div className={className}>
      {contactLinks.map((link) => {
        return <FooterSocialLink key={link.label} {...link} />;
      })}
    </div>
  );
};

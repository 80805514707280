import { graphql, StaticQuery } from "gatsby";
import { styled } from "linaria/react";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { CustomModal } from "../CustomModal/CustomModal";
import { GlobalStyle } from "../Theme/components/GlobalStyle";

const StyledMain = styled.main`
  max-width: 100vw;
  overflow: hidden;
  position: relative;

  & > * {
    &:first-child {
      margin-top: 30px;
    }
  }
`;

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <div className={GlobalStyle}>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: "description", content: "Sample" },
              {
                name: "keywords",
                content: "sample, something",
              },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <CustomModal />
          <Header />

          <StyledMain>{children}</StyledMain>
          <Footer />
        </div>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

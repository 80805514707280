import GatsbyImage from 'gatsby-image';
import { css } from 'linaria';
import React, { useCallback, useEffect, useState } from 'react';
import useLocalStorage from 'use-local-storage';
import { BaseButton } from '../CtaButton/CtaButton';
import { useInfoNotice } from '../NoticeInformation/hooks/useInfoNotice';
import clsx from 'clsx';


const centeredModal = css`
@media (min-width: 576px) {
  min-height: calc(100% - 4rem);
}
`;
export interface ModalProps {
  className?: string;
}

export const CustomModal = (props: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [confirmedNoticeIds, setConfirmedNoticeIds] = useLocalStorage<string[]>('confirmedNoticeIds', []);
  const notice = useInfoNotice();

  useEffect(() => {
    if (confirmedNoticeIds && !confirmedNoticeIds.includes(notice.id)) {
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    }
  }, [confirmedNoticeIds]);

  const onClose = useCallback(() => {
    setConfirmedNoticeIds([...confirmedNoticeIds, notice.id]);
    setIsOpen(false);
  }, [notice]);

  useEffect(() => {
    isOpen ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
  }, [isOpen]);

  return (
    <div className={clsx({
      'hidden': !isOpen,
      'block': isOpen,
    })}>
      <div className="modal-bg duration-500 ease-in-out fixed top-0 bottom-0 left-0 right-0 h-screen w-screen bg-black z-30 opacity-50 pointer-events-none"></div>

      <div
        className={clsx({
          'modal fixed fade z-50 duration-500 ease-in-out top-0 left-0 block w-full h-full outline-none overflow-x-hidden overflow-y-auto show': true,
          'opacity-0': !isOpen,
          'opacity-100': isOpen,
        })}
      >

        <div className={`modal-dialog modal-dialog-centered ${centeredModal} z-50 my-8 flex justify-center items-center  relative w-auto`}>
          <div
            className={` rounded-xl p-3 md:p-6 bg-white shadow-xl flex w-11/12 sm:9/12 md:w-auto max-w-lg  justify-center flex-col z-50`}
          >
            {notice.image && (
              <div className="pb-6">
                <GatsbyImage fluid={notice.image.childImageSharp.fluid} className="rounded-xl max-h-52" />
              </div>
            )}
            <h3 className="text-center text-primary text-xl md:text-2xl mb-3 font-semibold">{notice.shortInfo}</h3>
            <p className="text-center">{notice.content}</p>
            <div onClick={onClose} className="flex justify-center pt-3">
              <BaseButton>Rozumiem</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

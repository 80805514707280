import { styled } from "linaria/react";
import React from "react";

export interface FooterBottomProps {
  className?: string;
}

const MomentumLink = styled.a`
  margin: 0 5px;
  display: inline-flex;
`;

const currentYear = new Date().getFullYear()

export const FooterBottom = (props: FooterBottomProps) => {
  const { className = "" } = props;

  return (
    <div
      className={
        "mt-20 flex justify-center text-xs font-thin text-white text-opacity-30 text-center"
      }
    >
      <div>
        Stránka vytvorená spoločnosťou{" "}
        <MomentumLink
          href="https://mmntm.digital"
          target="_blank"
          rel="noopener noreferrer"
          className="text-opacity-50 text-white hover:text-opacity-70 transition-all duration-300 ease-in-out"
        >
          Momentum Digital
        </MomentumLink>{" "}
        © {currentYear} | Zásady ochrany osobných údajov
      </div>
    </div>
  );
};

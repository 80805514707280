export interface Route {
  label: string;
  to: string;
}

export const routes = {
  home: {
    label: "O nás",
    to: "/",
  },
  pricing: {
    label: "Cenník",
    to: "/cennik/",
  },
  gallery: {
    label: "Galéria",
    to: "/galeria/",
  },
  contact: {
    label: "Kontakt",
    to: "/kontakt/",
  },
  blog: {
    label: "Blog",
    to: "/blog/",
  },
};

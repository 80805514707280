import { graphql, useStaticQuery } from "gatsby";
import { Notice } from "../../../../lib/model/Home";

export const useInfoNotice = (): Notice.Informativenotice => {
  const {
    strapiNotice: { informative_notice },
  } = useStaticQuery(graphql`
    query useInfoNotice {
      strapiNotice {
        informative_notice {
          id
          shortInfo
          date
          content
          slug
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);
  return informative_notice;
};

import React from "react";
import { ReactNode } from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

export interface SocialLink {
  icon: ReactNode;
  label: string;
  url: string;
}

export const useSocialLinks = (): SocialLink[] => {
  return [
    {
      label: "Facebook",
      icon: <FaFacebookF />,
      url: "https://www.facebook.com/monzunbb",
    },
    {
      label: "Instagram",
      icon: <FaInstagram />,
      url: "https://www.instagram.com/monzun_bb/",
    },
    {
      label: "Youtube",
      icon: <FaYoutube />,
      url: "https://www.youtube.com/",
    },
  ];
};

import React from "react";
import { BiPhone } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { FaInstagram, FaMapMarkerAlt, FaYoutube } from "react-icons/fa";
import { SocialLink } from "../../FooterSocialLinks/hooks/useSocialLinks";

export const useSocialInfo = (): SocialLink[] => {
  return [
    {
      label: "048/240 00 40",
      icon: <BiPhone />,
      url: "tel:048/240 00 40",
    },
    {
      label: "recepcia@monzunbb.sk",
      icon: <FiMail />,
      url: "mailto:recepcia@monzunbb.sk",
    },
    {
      label: "Partizánska cesta 116, 974 01 Banská Bystrica",
      icon: <FaMapMarkerAlt />,
      url: "https://goo.gl/maps/pik5eMQ5k43hSG6m9",
    },
  ];
};

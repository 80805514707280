import { styled } from "linaria/react";
import React, { ReactNode } from "react";
import { theme } from "../Theme/Theme";

export interface CtaButtonProps {
  className?: string;
  children: ReactNode;
}

const Button = styled.div`
  background: ${theme.color.primaryGradient};
  background-size: 400px;

  &:hover {
    background-position: -100px;
  }
`;

export const BaseButton = (props: CtaButtonProps) => {
  const { children, className } = props;
  return (
    <Button
      className={` cursor-pointer flex w-auto py-3 px-6 text-white rounded-md shadow-lg font-medium text-lg hover:shadow-xl transition-all duration-300 ease-in-out ${className} `}
    >
      {children}
    </Button>
  );
};

export const CtaButton = (props: CtaButtonProps) => {
  const { children } = props;
  return <BaseButton className="py-4 px-8 lg:py-5 lg:px-10 text-xl font-semibold">{children}</BaseButton>;
};

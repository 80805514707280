import { css } from "linaria/lib";
import { theme } from "../Theme";

export const GlobalStyle = css`
  :global() {
    body  {
      font-family: "Poppins", sans-serif;
      color: #323232;

      &.overflow-hidden {
        overflow: hidden !important;
      }
    }

    * {
      margin: 0;
    }

    a {
      text-decoration: none;
    }

    /*  Used for hiding form labels, to achieve 100% accessibility */
    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
`;

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "gatsby";
import { css } from "linaria/lib";
import React, { useState } from "react";
import { routes } from "../../../lib/routes";
import { Container } from "../Container/Container";
import { BaseButton } from "../CtaButton/CtaButton";
import { theme } from "../Theme/Theme";
import { LogoLink } from "./components/LogoLink";
import { MobileMenu } from "./components/MobileMenu";
import "./header.css";

const NavLink = css`
  display: block;
  &.active > * {
    background: ${theme.color.primaryGradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SCROLL_SHADOW_THRESHOLD = 80;

const NavigationLink = ({ to = "", label = "", extra = null }) => {
  return (
    <Link
      target="_blank"
      rel="noopener noreferrer"
      className={
        NavLink +
        " text-gray-700 font-semibold text-lg flex space-x-3 items-center group"
      }
      partiallyActive={to !== "/"}
      activeClassName="active"
      to={to}
    >
      <div className="label">{label}</div>
      <div className="extra">{extra}</div>
    </Link>
  );
};

const renderNavItems = () =>
  Object.values(routes).map((route) => (
    <NavigationLink key={route.to} {...route} />
  ));

const HeaderInner = (props) => {
  const [scrolledToTop, setScrolledToTop] = useState(true);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -SCROLL_SHADOW_THRESHOLD) {
      setScrolledToTop(false);
    } else {
      setScrolledToTop(true);
    }
  });

  return (
    <header
      className={
        props.className +
        ` ${!scrolledToTop && "shadow-lg bg-white"} app-header`
      }
    >
      <Container>
        <nav>
          <LogoLink />
          <div className="nav-items space-x-8 hidden lg:flex">
            {renderNavItems()}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://monzun.auksys.com/master/index.php"
            >
              <BaseButton className="">Rezervačný systém</BaseButton>
            </a>
          </div>
          <MobileMenu className="flex lg:hidden z-100" />
        </nav>
      </Container>
    </header>
  );
};

export const Header = HeaderInner;

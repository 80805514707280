import React from "react";
import { styled } from "linaria/react";
import { Container } from "../Container/Container";
import { FooterSiteLinks } from "./components/FooterSiteLinks/FooterSiteLinks";
import { FooterSocialLinks } from "./components/FooterSocialLinks/FooterSocialLinks";
import { FooterContactInfo } from "./components/FooterContactInfo/FooterContactInfo";
import { FooterBottom } from "./components/FooterBottom/FooterBottom";

export interface FooterProps {
  className?: string;
}

const FooterInner = (props: FooterProps) => {
  return (
    <footer className={props.className + " mt-32"}>
      <Container>
        <h1 className="text-3xl mb-8 font-bold ">MONZUN</h1>
        <div className="grid gap-10 grid-cols-1 md:grid-cols-3">
          <FooterSiteLinks />
          <FooterSocialLinks />
          <FooterContactInfo />
        </div>
        <FooterBottom />
      </Container>
    </footer>
  );
};

export const Footer = styled(FooterInner)`
  background: #030720;
  color: white;

  padding: 50px 0 40px;
`;

import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../../../lib/routes";

export interface FooterSiteLinksProps {
  className?: string;
}

const footerLinks = [routes.home, routes.pricing, routes.contact, routes.gallery];

export const FooterSiteLinks = (props: FooterSiteLinksProps) => {
  const { className = "flex flex-col space-y-4" } = props;
  return (
    <div className={className}>
      {footerLinks.map((route) => (
        <div key={route.to}>
          <Link
            to={route.to}
            className="text-white text-opacity-60 transition-all duration-300 ease-in-out text-md font-medium hover:text-opacity-100"
          >
            {route.label}
          </Link>
        </div>
      ))}
    </div>
  );
};

import React from "react";
import { FooterSocialLink } from "../FooterSocialLink/FooterSocialLink";
import { useSocialLinks } from "./hooks/useSocialLinks";

export interface FooterSocialLinksProps {
  className?: string;
}

export const FooterSocialLinks = (props: FooterSocialLinksProps) => {
  const { className = "flex flex-col space-y-4" } = props;
  const socialLinks = useSocialLinks();
  return (
    <article className={className}>
      {socialLinks.map((link) => (
        <FooterSocialLink key={link.label} {...link} />
      ))}
    </article>
  );
};

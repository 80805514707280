import React, { ReactNode } from "react";

export interface ContainerProps {
  className?: string;
  children?: ReactNode | ReactNode[];
}

export const Container = (props: ContainerProps) => {
  const { children, className = "" } = props;
  return <div className={`container mx-auto px-6 ${className}`}>{children}</div>;
};
